<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        {{ surveyError }}
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <confirm-dialog ref="confirmDelete"></confirm-dialog>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <!--      Sponsor Container starts here      -->
            <v-card class="mx-auto my-12">
              <section-header
                :isCollapsable="false"
                :target="() => this.$refs.sponsorContainer"
              >Sponsor</section-header>
              <v-card-text>
                <v-container fluid ref="sponsorContainer">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <dt>Sponsor</dt>
                      <dd v-text="screener.sponsorName"></dd>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <dt>Engagements:</dt>
                      <dd v-if="screenerEngagements.length > 0">
                        {{ screenerEngagements.map(engagement => engagement.text).join(', ') }}
                      </dd>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <!--      Sponsor Container ends here      -->
            <v-card class="mx-auto mb-12">
              <v-container fluid ref="surveyContainer">
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Title"
                      rules="required|max:255"
                    >
                      <v-text-field
                        v-model="title"
                        counter
                        maxlength="255"
                        hint="The title of the Survey"
                        label="Title"
                        prepend-icon="mdi-account"
                        required
                        :disabled="title === 'DOB'"
                        aria-required="true"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-card class="mx-auto my-12">
              <section-header
                :isCollapsable="false"
                :target="() => self.$refs.pagesContainer"
                ><v-container>
                  <v-row>
                    <v-col cols="12" sm="4" class="mt-4">Pages</v-col>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="allowAddPage"
                      color="primary"
                      @click="addPage()"
                      class="my-5"
                      :loading="isEditingSurvey"
                    >
                      <v-icon aria-label="Add Page"> mdi-plus </v-icon>
                      Add Page
                    </v-btn>
                  </v-row>
                </v-container>
              </section-header>
              <v-container fluid ref="pagesContainer">
                <v-tabs
                  background-color="primary"
                  center-active
                  dark
                  show-arrows
                >
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <draggable v-model="tabs" class="v-slide-group__wrapper">
                    <v-tab
                      v-for="(id, index) in surveyPageIds"
                      :key="index"
                      @change="tabChanged()"
                      >{{ surveyPage(id).title }}
                      <v-icon
                        color="red"
                        class="d-none"
                        :ref="'tab-icon-' + id"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </v-tab>
                  </draggable>
                  <v-tab-item
                    v-for="(id, index) in surveyPageIds"
                    :key="index"
                    eager
                  >
                    <validation-observer :ref="'observer-' + id">
                      <v-container fluid>
                        <v-tabs vertical v-model="activeLanguageTab">
                          <v-tab
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(0)"
                            >English
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="'tab-icon-' + id + '-english'"
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab
                            v-for="(
                              languageOption, index
                            ) in surveyAdditionalLanguages"
                            :key="languageOption.id"
                            @change="tabChanged()"
                            @click="updateActiveLanguageTab(index + 1)"
                            >{{ languageOption.text }}
                            <v-icon
                              color="red"
                              class="d-none"
                              :ref="'tab-icon-' + id + '-' + languageOption.id"
                            >
                              mdi-alert-circle-outline
                            </v-icon></v-tab
                          >
                          <v-tab-item
                            eager
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="'observer-' + id + '-english'"
                            >
                              <page
                                :key="id"
                                :ref="'page-' + id"
                                :id="id"
                                class="item"
                                @deleteSurveyPage="deletePage($event)"
                                @updateSurvey="updateSurvey"
                              >
                              </page>
                            </validation-observer>
                          </v-tab-item>
                          <v-tab-item
                            eager
                            v-for="languageOption in surveyAdditionalLanguages"
                            :key="languageOption.id"
                            transition="slide-y-transition"
                            reverse-transition="slide-y-reverse-transition"
                          >
                            <validation-observer
                              :ref="'observer-' + id + '-' + languageOption.id"
                            >
                              <secondary-page
                                :key="id + '-' + languageOption.id"
                                :ref="
                                  'secondaryPage-' +
                                  id +
                                  '-' +
                                  languageOption.id
                                "
                                :id="id"
                                :languageTarget="languageOption"
                                :questionUpdated="questionUpdated"
                                class="item"
                              >
                              </secondary-page>
                            </validation-observer>
                          </v-tab-item>
                        </v-tabs>
                      </v-container>
                    </validation-observer>
                  </v-tab-item>
                </v-tabs>
              </v-container>
            </v-card>
          </v-container>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import Page from "@admin/components/surveys_new/Page.vue";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";
import draggable from "vuedraggable";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "Form",
  components: {
    SectionHeader,
    ValidationObserver,
    ValidationProvider,
    Page,
    ConfirmDialog,
    draggable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      snackbar: false,
      self: this,
      activeLanguageTab: 0,
      questionUpdated: 0,
      surveyAdditionalLanguages: [],
      secondaryLanguageOptions: [],
    };
  },
  computed: {
    ...mapState("screeners", { screener: "screener", screenerEngagements: "screenerEngagements" }),
    ...mapState("surveysNew", { surveyError: "error", survey: "survey" }),
    ...mapFields("surveysNew", {
      title: "survey.title",
    }),
    ...mapGetters("surveysNew", {
      surveyPage: "surveyPage",
      getSurveyPageIds: "surveyPageIds",
      getSurveyPageQuestionsPerPage: "surveyPageQuestionsPerPage",
      isEditingSurvey: "isEditingSurvey",
    }),
    allowAddPage() {
      // Don't allow for adding a page if this survey title is DOB as this is a system survey and should not be changed.
      return this.survey.title !== "DOB";
    },
    surveyPageIds: {
      get() {
        return this.getSurveyPageIds;
      },
      set(value) {
        this.changeSurveyPageOrder(value);
      },
    },
    tabs: {
      get() {
        let allTabs = [...this.surveyPageIds];
        return allTabs;
      },
      set(value) {
        this.changeSurveyPageOrder(value);
      },
    },
    cancelButtonRoute() {
      return this.redirectRoute;
    },
    redirectRoute() {
      return {
        name: "SurveysNewView",
        params: { id: this.survey.id },
      };
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("screeners", ["getScreener"]),
    ...mapActions("surveysNew", {
      addSurveyPage: "addPage",
      clearSurvey: "clearSurvey",
      editSurvey: "editSurvey",
      getSurvey: "getSurvey",
      getSponsorSurveys: "getSponsorSurveys",
      changeSurveyPageOrder: "changePageOrder",
      deleteSurveyPage: "deletePage",
    }),
    ...mapActions("questionCategories", {
      getQuestionCategories: "getQuestionCategories",
      refreshQuestionCategories: "refreshState",
    }),
    ...mapActions("tags", {
      getTags: "getTags",
      refreshTags: "refreshState",
    }),
    ...mapActions("sponsors", {
      getSponsor: "getSponsor",
    }),
    addPage() {
      // Don't allow for adding a new page while a survey is being edited
      if (this.isEditingSurvey) return;
      this.addSurveyPage(this.screener.versionId);
    },
    deletePage(id) {
      this.$refs.confirmDelete
        .open(
          "Confirm",
          "<div>" +
            "<p>Are you sure you want to delete this page?</p>" +
            "</div>"
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteSurveyPage(id);
          }
        });
    },
    tabChanged() {
      this.updateAllTabIcons();
    },
    updateAllTabIcons() {
      let self = this;
      //get all identities
      const pageIds = [...this.surveyPageIds];

      let newPageIds = [...pageIds];
      //add any additional languages identities
      for (let i in pageIds) {
        //add default english
        newPageIds.push(pageIds[i] + "-english");
      }

      //for each identity, validate and show validation icon if invalid
      newPageIds.forEach(function (id) {
        let pageObserver = null;
        if (
          self.$refs["observer-" + id] !== undefined &&
          self.$refs["observer-" + id][0] !== undefined
        ) {
          pageObserver = self.$refs["observer-" + id][0];
        }

        if (pageObserver !== null) {
          let tabIcons = self.$refs["tab-icon-" + id];

          if (!Array.isArray(tabIcons)) {
            tabIcons = [tabIcons];
          }

          pageObserver.validate().then((isValid) => {
            for (let i in tabIcons) {
              if (tabIcons[i] !== undefined) {
                if (!isValid) {
                  tabIcons[i].$el.classList.remove("d-none");
                } else {
                  tabIcons[i].$el.classList.add("d-none");
                }
              }
            }
          });
        }
      });
    },
    updateSurvey() {
      this.questionUpdated++;
    },
    async submit() {
      //update all tab validation icons
      this.updateAllTabIcons();

      // Don't allow for submitting if the submission process was already initiated or the current contextual
      // survey is already indicated to be saved (a local submission is tracked in addition to isEditingSurvey
      // to account for restricting this process while any logic that needs processing is completed
      // after a survey is finished being edited)

      if (
        this.isSubmitting ||
        this.isEditingSurvey ||
        !(await this.$refs.observer.validate())
      ) {
        return;
      }

      this.isSubmitting = true;

      const promises = [];

      promises.push(this.editSurvey(this.id));

      return Promise.all(promises).then((responses) => {
        const [surveySuccess] = responses.shift();

        if (surveySuccess) {
          // Identify what the redirect route should be before clearing survey
          // so we know where to go next
          const redirect = this.redirectRoute;
          this.clearSurvey(); // force re-pull from db

          this.$router.push(redirect);
        } else {
          this.snackbar = true;
        }
        this.isSubmitting = false;
      });
    },
  },
  async beforeMount() {
    this.isLoading = true;

    const promises = [];

    promises.push(
      this.getSurvey(this.id).then(([success]) => {
        if (!success) {
          this.$router.push({ name: "NotFound" });
        }
        this.setTitleObject(this.survey.title);
        this.setBreadcrumbs({
          self: {
            id: this.survey.id,
          },
        });
      })
    );

    promises.push(this.getQuestionCategories());
    promises.push(this.getTags());

    await Promise.all(promises);

    // Once a survey is loaded, the following promises can be handled
    const followupPromises = [];
    followupPromises.push(
      this.getScreener({
        id: this.survey.screenerId,
      })
    );
    await Promise.all(followupPromises);
    await this.getSponsorSurveys({
      sponsorId: this.screener.sponsorId,
    });
    await this.getSponsor(this.screener.sponsorId);

    this.setActionButtons([
      {
        component: "CancelButton",
        attributes: {
          to: "/surveys-new/view/" + this.survey.id,
        },
        index: 0,
      },
      {
        component: "SaveButton",
        attributes: {
          onSubmit: () => this.submit(),
        },
        index: 1,
      },
    ]);
    this.isLoading = false;
  },
  updated() {
    //after mounted load any validation icons
    this.updateAllTabIcons();
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearSurvey();
    this.refreshQuestionCategories();
    this.refreshTags();
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
